import { Box, FormControl } from '@material-ui/core';
import { FC, FormEvent, MouseEvent, useState } from 'react';
import { InfoHeader, InfoText, InfoWrapper, InfoWrapperButtons } from '../AuthorizationDialog.styles';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';

import { Button } from '@/components/Button/Button';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { OfferType } from '@/models/OfferTypeName.enum';
import { PaymentType } from '@/models/Payment.enum';
import { PromoCodeField } from '@/components/PromoCodeField';
import { SelectOffer } from '@/components/SelectOffer';
import { Typography } from '@/components/Typography';
import axios from 'axios';
import { css } from '@emotion/react';
import { useAccountState } from '@/context/accountContext';
import { useAuthorizationDialog } from '../AuthorizationDialog.hooks';

enum BuyOfferStep {
  SelectOffer,
  Payment,
}

interface BuyOfferDialogProps {
  originalOfferId?: string;
  typename?: OfferType;
  offerIds: IOfferIds[];
  selectedOffer: IOfferItem | null;
  initialOffer?: IOfferItem | null;
  onSelectOffer: (offer: IOfferItem) => void;
}

export const BuyOfferDialogStep: FC<BuyOfferDialogProps> = ({
  offerIds,
  originalOfferId,
  selectedOffer,
  initialOffer,
  onSelectOffer,
}) => {
  const { auth } = useAccountState();
  const { validatePromoCode, setStep } = useAuthorizationDialog({ auth });
  const dialogDispatch = useDialogDispatch();

  const [promoCode, setPromoCode] = useState<string>();
  const [invalidPromoCode, setInvalidPromoCode] = useState(false);
  const [reducedPrice, setReducedPrice] = useState<number>();

  const [promoCodeOffer, setPromoCodeOffer] = useState<IOfferItem | null>(null);

  const handlePromoCodeChange = (code: string) => {
    setInvalidPromoCode(false);
    setPromoCode(code);
  };
  const updateReducedPrice = async (code: string, offerId: string) => {
    try {
      const { data } = await axios.post('/api/promo-code', {
        token: auth.token,
        offerId,
        code,
      });
      if (data) {
        setReducedPrice(data.reducedPrice);
      } else {
        setInvalidPromoCode(true);
        return false;
      }
    } catch {
      setReducedPrice(undefined);
    }
  };

  const handleValidatePromoCode = async () => {
    if (!promoCode || !selectedOffer?.id) {
      return false;
    }

    const promoCodeStatus = await validatePromoCode(
      selectedOffer.id === 'pass-offer' ? offerIds[0].offerId : selectedOffer.id,
      promoCode
    );

    if (promoCodeStatus?.status === 'valid') {
      updateReducedPrice(promoCode, selectedOffer.id);
      return true;
    } else if (promoCodeStatus?.status === 'wrong-offer' && promoCodeStatus.offer) {
      setPromoCodeOffer(promoCodeStatus.offer);
      setInvalidPromoCode(true);
      updateReducedPrice(promoCode, promoCodeStatus.offer.id);
      return false;
    } else {
      setInvalidPromoCode(true);
      return false;
    }
  };

  const handleSubmit = (onSubmit?: () => void) => async (event?: MouseEvent | FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (promoCode && selectedOffer?.id) {
      const validPromoCode = await handleValidatePromoCode();
      if (!validPromoCode) {
        setInvalidPromoCode(true);
        return;
      }
    }

    dialogDispatch({
      type: dialogActionTypes.DIALOG_PAYMENT,
      payload: {
        type: PaymentType.MakePayment,
        selectedOffer: selectedOffer,
        originalOfferId: originalOfferId ?? undefined,
        promoCode: promoCode ?? undefined,
        reducedPrice: undefined,
      },
    });
    onSubmit?.();
  };

  const handleAcceptPromoCodeOffer = async () => {
    await updateReducedPrice(promoCode!, promoCodeOffer!.id);
    onSelectOffer(promoCodeOffer!);
    setPromoCodeOffer(null);
    setInvalidPromoCode(false);

    dialogDispatch({
      type: dialogActionTypes.DIALOG_PAYMENT,
      payload: {
        type: PaymentType.MakePayment,
        selectedOffer: promoCodeOffer!,
        originalOfferId: originalOfferId ?? undefined,
        promoCode: promoCode ?? undefined,
        reducedPrice: reducedPrice,
      },
    });
  };

  const handleContinueWithoutPromoCode = () => {
    setPromoCode('');
    setReducedPrice(undefined);
    setPromoCodeOffer(null);
    setInvalidPromoCode(false);
    dialogDispatch({
      type: dialogActionTypes.DIALOG_PAYMENT,
      payload: {
        type: PaymentType.MakePayment,
        selectedOffer: selectedOffer,
        originalOfferId: originalOfferId ?? undefined,
        promoCode: undefined,
        reducedPrice: reducedPrice,
      },
    });
  };

  const handleGoBackToSelect = () => {
    setPromoCodeOffer(null);
    setInvalidPromoCode(false);
  };

  return (
    <>
      {promoCodeOffer && (
        <InfoWrapper>
          <InfoHeader align="left" variant="body2">
            Kampanjkod gäller inte vald prenumeration
          </InfoHeader>
          <InfoText align="left" variant="body2">
            Du har en kampanjkod som gäller för <strong>{promoCodeOffer.title}</strong> men har valt{' '}
            {selectedOffer ? <strong>{selectedOffer.title}</strong> : 'inte valt en prenumeration'}
            . <br />
            <br />
            Klicka på "Fortsätt med kampanjkod" för att starta <strong>{promoCodeOffer.title}</strong> för{' '}
            <strong>
              {(reducedPrice ?? promoCodeOffer.priceInCents) / 100} {promoCodeOffer.currency}
            </strong>{' '}
            och använda din kampanjkod
            {selectedOffer ? (
              <>
                , eller välj "Fortsätt med vald prenumeration" för att starta <strong>{selectedOffer.title}</strong> för{' '}
                <strong>
                  {selectedOffer.priceInCents / 100} {selectedOffer.currency}
                </strong>
              </>
            ) : (
              ''
            )}
            .
          </InfoText>
          <InfoWrapperButtons>
            {selectedOffer && (
              <>
                <Button size="large" color="red" onClick={handleAcceptPromoCodeOffer}>
                  Fortsätt med kampanjkod
                </Button>
                <Button size="large" color="white" onClick={() => handleContinueWithoutPromoCode()}>
                  Fortsätt med vald prenumeration
                </Button>
              </>
            )}
            <Button color="white" size="large" onClick={() => handleGoBackToSelect()}>
              Tillbaka
            </Button>
          </InfoWrapperButtons>
        </InfoWrapper>
      )}

      {auth.error && (
        <Typography
          variant="bodySmall"
          styles={css`
            text-align: center;
          `}
        >
          Betalning kan inte påbörjas – eventuell kampanjkod är ogiltig eller förbrukad, skriv in korrekt kampanjkod
          eller mejla kontakt@drakenfilm.se
        </Typography>
      )}

      <>
        <Box display={promoCodeOffer ? 'none' : undefined} mb={10}>
          <form autoComplete="off" onSubmit={handleSubmit()}>
            <Box mb="2rem">
              <Typography variant="titleLarge" fontWeight="500">
                Starta prenumeration
              </Typography>
            </Box>
            <Typography variant="bodySmall" fontWeight="300">
              Välj den prenumerationstyp du vill starta. Om du har en presentkortskod eller en rabattkod skriver du in
              den i fältet för kampanjkod för att starta rätt prenumeration.
            </Typography>
            <Box mb={2} mt={5}>
              <PromoCodeField onSetValue={handlePromoCodeChange} promoCode={promoCode} error={invalidPromoCode} />
            </Box>
            <SelectOffer
              initialOffer={initialOffer}
              includedIds={offerIds}
              selectedOffer={selectedOffer}
              onSelectedChange={onSelectOffer}
            />
            <FormControl fullWidth margin="normal">
              <Button size="small" color="red" disabled={!selectedOffer?.id || invalidPromoCode}>
                Gå vidare
              </Button>
            </FormControl>
          </form>
        </Box>
      </>
    </>
  );
};
