import { Box, Typography } from '@material-ui/core';
import { FC, useCallback, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

import { AdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import { IOfferItem } from '@/models/IOffer.interface';
import Payment from '@/components/Payment';
import PaymentErrorMessage from '@/components/PaymentErrorMessage';
import { PaymentType } from '@/models/Payment.enum';
import { SelectedOffer } from './SelectedOffer';
import { useDialogState } from '@/context/authorizationDialogContext';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Radikal-medium',
    fontSize: 26,
    margin: theme.spacing(3, 0, 4),
  },
}));

interface PaymentDialogProps {
  type?: PaymentType;
  result?: AdyenPaymentResultCode;
  newOffer?: IOfferItem;
  originalOfferId?: string;
  promoCode?: string;
}

export const PaymentDialogStep: FC<PaymentDialogProps> = ({
  type,
  result,
  newOffer,
  originalOfferId: _originalOfferId,
  promoCode: _promoCode,
}) => {
  const classes = useStyles();
  const { data } = useDialogState();
  const [error, setError] = useState(false);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  let title: string | undefined;
  if (type === PaymentType.SavePayment) {
    title = 'Uppdatera betaluppgifter';
  } else if (result === AdyenPaymentResultCode.Authorised) {
    title = 'Välkommen till ditt nya filmliv!';
  }

  // Don't include offerId and promoCode when payment already successful
  const offerId = result !== AdyenPaymentResultCode.Authorised ? newOffer?.id : undefined;
  const offerType = result !== AdyenPaymentResultCode.Authorised ? newOffer?.__typename : undefined;
  const originalOfferId = result !== AdyenPaymentResultCode.Authorised ? _originalOfferId : undefined;
  const promoCode = result !== AdyenPaymentResultCode.Authorised ? _promoCode : undefined;

  return (
    <>
      {title && (
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      )}
      <Box mb={4}>
        <SelectedOffer offer={data?.selectedOffer} reducedPrice={data?.reducedPrice} />
      </Box>
      <Box mb={4}>
        {/*
            Ideally we check if user has subscription, AND is upgrading.
            Checking for 'PassType' specifically covers the case
            of festival visitors getting a confusing message.
        */}
        {offerType && offerType !== 'PassType' && (
          <Typography variant="body2">
            Det du har kvar på din tidigare prenumeration har dragits av från ditt pris. Eventuell testperiod,
            presentkort eller rabatt som gällde för din tidigare prenumeration har nu förbrukats och kan inte användas
            för din nya prenumeration.
          </Typography>
        )}
      </Box>
      <PaymentErrorMessage error={error} />
      <Payment
        type={type}
        result={result}
        offerId={offerId}
        originalOfferId={originalOfferId}
        promoCode={promoCode}
        onError={handleError}
      />
    </>
  );
};
